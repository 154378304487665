.corpo_texto {
    min-height: 200px !important;
}

.nao_lido {
    --bs-table-bg: #ffc107!important;
}

.ABERTO {
    background: #11a5ff !important;

}

.CONCLUIDO {
    background: #f60000 !important;
    color: white;
}

.EM_ANDAMENTO {
    background: #2c9700 !important;
    color: white;
}