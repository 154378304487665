.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}

html, body {
    height: 100%;
}

body {

    align-items: center;
    background-color: #f5f5f5;
}