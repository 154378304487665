.bg-verde{
  background: #005f33;
}

.font-verde{
  color: #005f33;
}


.btn-verde {
  background: #005f33 !important;
  color: white;
}

.btn-verde:hover {
  background: #08807b !important;
}
.btn-verde-out-line {
  border-color: #005f33 !important;
  color: #005f33 !important;
}
.btn-verde-out-line:hover {
  background: #005f33 !important;
  color: white !important;
}

h1, th, td, a{
  color: #005f33 !important;
}


input:focus
{
  background-color:#F0F9F8 !important;
  border-color: rgb(132, 192, 189)!important;
  box-shadow: 0 0 0 0.25rem rgb(132, 192, 189) !important;
}

select:focus
{
  background-color:#F0F9F8 !important;
  border-color: rgb(132, 192, 189)!important;
  box-shadow: 0 0 0 0.25rem rgb(132, 192, 189) !important;
}

textarea:focus
{
  background-color:#F0F9F8 !important;
  border-color: rgb(132, 192, 189)!important;
  box-shadow: 0 0 0 0.25rem rgb(132, 192, 189) !important;
}

.nav-link{
  border-radius: 10px ;
  font-weight: bold !important;
}
.nav-link:hover{
  background: #E9ECEC;
  color: whitesmoke !important;
}
label {
  font-weight: bold;
}
td {
    vertical-align: middle; /* alinhamento vertical */
}


.color-1 { background-color: rgb(8, 128, 123); }
.color-2 { background-color: rgb(70, 160, 156); }
.color-3 { background-color: rgb(132, 192, 189); }
.color-4 { background-color: rgb(193, 223, 222); }
.color-5 { background-color: rgb(255, 255, 255); }
