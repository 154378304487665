.loading{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    width: 225px;
    margin: auto;
    opacity: 1;
}

.fundo-loading{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background-color: #FFF;
    opacity: 0.5;
    width: 100%;
    height: 100%;
}